.header-title {
    border-left: 0.1rem solid hsl(0 0% 0% / 0.2);
    color: var(--ink-black);
    font-size: var(--body);
    font-weight: 400;
    margin-left: 2em;
    opacity: 0.7;
    margin: 0;
    padding: 1em;
    padding-left: 1.5em;
}