.dot {
    fill: var(--raspberry);
    max-height: 4.5in;
    user-select: none;

    .value {
      font-family: var(--font2);
      font-size: 0.75rem;
      font-weight: 800;
      text-align: center;
    }
    
    .label {
        font-size: 0.3rem;
        font-weight: 400;
    }
  }
  
  