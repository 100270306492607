.map {
    --cyan-lt: hsl(194, 100%, 70%);
    --raspberry-lt: hsl(328, 100%, 70%);
    --concrete-lt: hsl(240, 4%, 85%);

    background-color: var(--blue-jeans);
    
    .hrr,
    .county {
        mix-blend-mode: multiply;
        opacity: 1;
    }

    .hrr:hover,
    .county:hover,
    .state:hover {
        opacity: 0.7;
        stroke-width: 0.3;
    }
}

.map-tip {
    background-color: var(--ink-black);
    border-radius: 0.2em;
    color: white;
    font-family: var(--font2);
    font-size: clamp(1.4rem, 1vw, 1.8rem);
    font-weight: 700;
    padding: 0.5em;
    position: fixed;
    z-index: 100;
}
