.client-logo {
    display: flex;
    flex-direction: column;
    position: relative;

    .logo-wrap {
        display: grid;
        height: 100%;
        margin-bottom: calc(var(--gap-sm) / 2);
        place-items: center;
        position: relative;

        &.grayscale {
            img {
                filter: grayscale(1);
            }
        }
    }

    img {
        display: block;
        height: auto;
        max-width: 25vw;
        max-height: 10vw;
        width: auto;
    }

    p {
        background-color: hsla(213, 100%, 25%, 0.02);
        color: hsla(213, 100%, 25%, 0.6);
        font-size: clamp(1.2rem, 1.25vw, 1.6rem);
        font-weight: 700;
        margin-top: auto;
        padding: 0.2em;
        text-align: center;

        &:only-child {
            background-color: transparent;
            border: 0.1rem solid hsla(213, 100%, 25%, 0.2);
            border-radius: 0.2em;
            color: hsla(213, 100%, 25%, 1);
            font-size: 2.25vw;
            font-weight: 800;
            line-height: 1.2;
            margin: auto 0;
            padding: 0.5em;
        }
    }
}