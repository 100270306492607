.features {
  padding: var(--gap-sm);

  text {
    fill: var(--blue-jeans);
  }

  .txt-heading {
      font-size: 9pt;
      font-weight: 800;
  }

  .txt-body {
      font-size: 6pt;
      font-weight: 400;
  }

  .feat-1 {
    rect {
      fill: var(--cyan);
    }
  }

  .feat-2 {
    rect {
      fill: var(--orange-slice);
    }
  }

  .feat-3 {
    rect {
      fill: var(--raspberry);
    }
  }

  .feat-4 {
    rect {
      fill: var(--blueberry);
    }
  }
}

