.steps {
    --color: var(--cyan);
    padding: var(--gap-sm);
     
    .heading {
        fill: var(--blue-jeans);
        font-size: 10pt;
        font-weight: 800;
    }

    .step {
        fill: var(--blue-jeans);
        font-size: 9pt;
        font-weight: 800;

        tspan {
            fill: var(--color);
        }
    }

    .body {
        fill: var(--blue-jeans);
        font-size: 5.3pt;
        font-weight: 400;
    }

    .bold {
        fill: var(--ocean);
        font-weight: 700;
    }

    .outer-bullet,
    .inner-bullet {
        fill: white;
        stroke-width: 1;
        stroke: var(--color);
    }

    .line {
        fill: var(--color);
    }
}