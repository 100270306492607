.donut {
    font-family: var(--font2);
    transform: scale(0.90);
    user-select: none;

    .ring {
        transform: rotateZ(-90deg);
        transform-origin: center;
    }
    
    .segment {
        stroke: var(--clouds);
    }
    
    .value,
    .label {
        fill: var(--blue-jeans);
    }
    
    .value {
        font-size: 0.7rem;
        font-weight: 800;
        text-align: center;
    }
    
    .label {
        font-size: 0.25rem;
        font-weight: 600;
    }
}

