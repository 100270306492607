.nps {
    background-color: var(--blue-jeans);
    padding: var(--gap-sm);

    .score {
        fill: var(--raspberry);
        font-family: var(--font2);
        font-size: 24pt;
        font-weight: 800;
    }

    .score-label {
        fill: white;
        font-size: 14pt;
        font-weight: 800;
    }

    .label {
        fill: white;
        font-size: 9pt;
        font-weight: 600;
    }
}

