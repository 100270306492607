.ui-card {
    border: 0.1rem solid var(--clouds);
    border-radius: 0.25em;
    display: flex;
    flex-direction: column;

    .card-content {
        align-items: center;
        display: grid;
        height: 100%;
        padding: 1.5em;
        position: relative;
    }

    footer {
        background-color: hsl(210 1% 91% / 0.4);
        display: flex;
        font-size: var(--sm);
        font-weight: 700;
        gap: 1em;
        justify-content: space-between;
        margin-top: auto;
        padding: 1.5em;
    }
}
