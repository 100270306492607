:root {
  // Viewport-based scaled font sizes (hs = scale on viewport width, vs = scale on viewport height)
  // utilities.js has functions that will override these variables based on parentNode height for granular scalability based on parent, rather than viewport, which is the default here and current CSS limitation
  --hs-body: clamp(1rem, calc(100vw * 0.028), 30rem);
  --hs-h1: calc(var(--hs-body) * 3);
  --hs-h2: calc(var(--hs-body) * 2);
  --hs-h3: calc(var(--hs-body) * 1.6);

  --vs-body: clamp(1rem, calc(100vh * 0.028), 30rem);
  --vs-h1: calc(var(--hs-body) * 3);
  --vs-h2: calc(var(--hs-body) * 2);
  --vs-h3: calc(var(--hs-body) * 1.6);
}

html, body {
  font-family: var(--font1);
  background: white;
  height: 100vh;
  min-height: auto;
}

#root {
  align-items: center;
  display: grid;
  height: 100vh;
  width: 100vw;
}

.app {
  align-items: start;
  display: grid;
  grid-template-columns: 50% 50%;
}

.right {
  border-left: 0.1rem solid var(--clouds);
  padding: var(--gap-sm);
}
