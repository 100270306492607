.metric {  
    .label {
        font-size: 5pt;
        font-weight: 700;
        margin-bottom: 0.5em;
        text-align: center;
    }
    
    .value {
        font-family: var(--font2);
        font-size: 13pt;
        font-weight: 800;
        text-align: center;
    }
}

